import {
  QueryExtendedBookingResponse,
  SortOrder,
} from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import {
  addOrderAndTransaction,
  bookingToBookingDTO,
} from '../../../utils/mappers/bookings-mapper/bookings-mapper';
import { ApiContext } from '../../types';
import {
  AllowedActions,
  ApplicationState,
  BookingDTO,
  Bookings,
  BookingsGroup,
  TabTypes,
} from '../../../types';
import { getServiceIdFromExtendedBooking } from '../../../utils/bookInfo';

export interface GetEnrichedBookingsArgs {
  type: TabTypes;
  applicationState: ApplicationState;
}

export const getEnrichedBookings = async ({
  flowAPI,
  type,
  api,
  applicationState,
}: GetEnrichedBookingsArgs & ApiContext): Promise<Bookings> => {
  let bookings: QueryExtendedBookingResponse;
  const user = flowAPI.controllerConfig.wixCodeApi.user.currentUser;
  const contactId = user.id;
  let servicesResponse: Service[] = [];

  // Query Bookings
  if (type === TabTypes.UPCOMING) {
    bookings = await api.queryBookings({
      contactId,
      sortOrder: SortOrder.ASC,
      endDateComparisonOperator: '$gt',
    });
  } else {
    bookings = await api.queryBookings({
      contactId,
      sortOrder: SortOrder.DESC,
      endDateComparisonOperator: '$lte',
    });
  }

  // Query Services
  const serviceIds = bookings?.extendedBookings?.map((booking) =>
    getServiceIdFromExtendedBooking(booking),
  );

  if (serviceIds?.length) {
    servicesResponse = await api.queryServices({ serviceIds });
  }

  let bookingsDTO: BookingDTO[] | undefined = bookings?.extendedBookings?.map(
    (booking) =>
      bookingToBookingDTO({
        bookingEntry: booking,
        services: servicesResponse,
        businessAddress: applicationState.businessInfo?.formattedAddress,
      }),
  );

  // Filter out bookings without service
  bookingsDTO = bookingsDTO?.filter((booking) => booking.service);

  // Create Groups with allowed actions and staff From bookingsDTO
  const groupIds: string[] =
    bookingsDTO
      ?.filter((booking) => booking.groupId)
      .map((booking) => booking.groupId!) || [];
  const groupAllowedActions: { [x: string]: AllowedActions } = {};
  const groups: { [key: string]: BookingsGroup } = {};

  if (groupIds?.length) {
    const allowedActionsResponse = await api.getGroupAllowedActions({
      groupIds,
    });

    allowedActionsResponse?.results?.forEach((allowedAction) => {
      if (allowedAction?.itemMetadata?.id && allowedAction.item) {
        groupAllowedActions[allowedAction.itemMetadata.id] = allowedAction.item;
      }
    });
  }

  bookingsDTO?.forEach((booking) => {
    const groupId = booking.groupId || booking.bookingId;

    if (!groups[groupId]) {
      groups[groupId] = {
        bookings: [],
        ...(booking.groupId ? { groupId: booking.groupId } : {}),
        allowedActions: booking.groupId
          ? groupAllowedActions[groupId]
          : booking.allowedActions,
      };
    }

    groups[groupId].bookings.push({ ...booking });
  });

  // Sort Groups by Type
  const sortedGroups =
    Object.values(groups).sort((a, b) =>
      type === TabTypes.UPCOMING
        ? a.bookings[0].start - b.bookings[0].start
        : b.bookings[0].start - a.bookings[0].start,
    ) || [];

  if (sortedGroups.length === 0) {
    return {
      groups: [],
    };
  }

  // Add order and transactions to each group
  const { orders = [], transactions = [] } = await api.getOrdersAndTransactions(
    {
      bookings: {
        groups: sortedGroups,
      },
    },
  );

  const enrichedBooking = addOrderAndTransaction({
    bookings: {
      groups: sortedGroups,
    },
    orders,
    transactions,
  });

  return enrichedBooking;
};
